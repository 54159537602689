/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header";
import { Link } from "gatsby";

import '@elastic/eui/dist/eui_theme_light.css';
import "./layout.css";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1400,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <footer style={{
          marginTop: `2rem`
        }}>
          © {new Date().getFullYear()}, Рейтинг Форекс брокеров - 
          {` | `}
          <Link to="/sitemap/">Карта сайта</Link>
          {` | `}
          <Link to="/about/">О сайте</Link>
          {` | `}
          <Link to="/contacts/">Контакты</Link>
          {` | `}
          <Link to="/disclaimer/">Отказ от ответственности</Link>
          {` | `}
          <Link to="/privacy-policy/">Политика конфиденциальности</Link>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
