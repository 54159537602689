import React from 'react';
import { Link } from 'gatsby';
import {
  EuiHeader,
  EuiHeaderSectionItem,
  EuiHeaderLogo,
  EuiHeaderLinks,
  EuiHeaderLink,
  EuiFlexItem,
  EuiSpacer,
  EuiFlexGroup,
  EuiText,
  EuiIcon
} from '@elastic/eui';

import FullSidebarMenu from './../components/full_sidebar_menu';

import Search from './search';

const Header = () => {


    const [windowWidth, setWindowWidth] = React.useState(typeof window !== 'undefined' && window.innerWidth);

    React.useEffect(() => {
      if (typeof window !== 'undefined' && typeof window.addEventListener === 'function') {

        window.addEventListener('resize', () => {
          setWindowWidth(window.innerWidth)
        }, true);
      } 
    }, [])


    return (
      <EuiHeader style={{ justifyContent: 'center' }}>

        {windowWidth > 768 &&
       <EuiFlexItem  style={{ width: '95%', maxWidth: 1230 }}>
              <EuiFlexGroup>
                <EuiFlexItem>
                  <Link to={`/`}>
                    <EuiHeaderSectionItem border="none">
                      <EuiHeaderLogo>Рейтинг Форекс брокеров 2023</EuiHeaderLogo>
                    </EuiHeaderSectionItem>
                  </Link>
                </EuiFlexItem>

                <EuiFlexItem style={{ flexBasis: 'auto' }}>
                  <EuiHeaderSectionItem border="none">
                    <Search />
                  </EuiHeaderSectionItem> 
                </EuiFlexItem>  

                <EuiFlexItem>
                  <EuiHeaderSectionItem border="none">
                    <EuiHeaderLinks style={{ marginLeft: 'auto' }}>
                      <Link to={`/about/`}><EuiHeaderLink>О сайте</EuiHeaderLink></Link>
            
                      <Link to={`/contacts/`}><EuiHeaderLink>Контакты</EuiHeaderLink></Link>
                    </EuiHeaderLinks>
                  </EuiHeaderSectionItem> 
                  </EuiFlexItem>
                </EuiFlexGroup>
        </EuiFlexItem> 
        }



          {windowWidth < 768 &&
          <Link to={`/`}>
            <EuiHeaderSectionItem border="none">
              <EuiHeaderLogo>Рейтинг Форекс брокеров 2023</EuiHeaderLogo>
            </EuiHeaderSectionItem>
          </Link>  
          }

          {windowWidth < 768 &&
            <EuiHeaderSectionItem border="none">
              <EuiHeaderLinks>
                <Link to={`/search/`}><EuiHeaderLink><EuiIcon type="search" />&nbsp;&nbsp;&nbsp;Поиск по брокерам</EuiHeaderLink></Link>
                <EuiSpacer size="s" />
              
                <FullSidebarMenu /> 
                <EuiSpacer size="s" />
                <Link to={`/about/`}><EuiHeaderLink>О сайте</EuiHeaderLink></Link>
                <Link to={`/contacts/`}><EuiHeaderLink>Контакты</EuiHeaderLink></Link>
              </EuiHeaderLinks>
            </EuiHeaderSectionItem> 
          }

      </EuiHeader>
      
    );
  };

export default Header;  
  
  