import React from 'react';

import {
    EuiListGroup,
    EuiListGroupItem,
  } from '@elastic/eui';

import { Link } from 'gatsby';
import { useStaticQuery, graphql } from "gatsby";


  const FullSidebarMenu = () => {

    const GET_CATEGORIES = graphql`
    {
        allContentfulCategory {
          edges {
            node {
              slug
              name
              shortname
            }
          }
        }
    }
    `;

    const { allContentfulCategory: { edges: categories } = {} } = useStaticQuery(GET_CATEGORIES);

    const viewCategories = categories.map(cat => {
        return <Link style={{ color: "#343741" }} title={cat.node.name} to={`/${cat.node.slug}/`}>
            <EuiListGroupItem style={{ fontSize: 15 }} onClick={() => {}} label={cat.node.shortname} />
        </Link>
    })
    
    
    return (
          <EuiListGroup style={{ borderLeft: "1px solid" }}>
            <Link style={{ color: "#343741" }} to={`/`}><EuiListGroupItem style={{ fontSize: 15 }} onClick={() => {}} label="Все брокеры" /></Link>
                
            {viewCategories}
    
          </EuiListGroup>
      );
}
  export default FullSidebarMenu;

