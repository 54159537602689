import React from 'react';
import Fuse from 'fuse.js';
import { transliterate as tr, slugify } from 'transliteration';

import {
    EuiFieldSearch

  } from '@elastic/eui';

import { useStaticQuery, graphql } from "gatsby";
import { navigate } from 'gatsby';

import { searchStore } from './../apollo/client';

const Search = () => {
    const [value, setValue] = React.useState('');
    const [isClearable, setIsClearable] = React.useState(true);


    const GET_BROKERS = graphql`
    {
    allContentfulBroker {
    edges {
      node {
        name
        slug
        established
        max_leverage
        min_deposit
        spreads {
          name
          name_ru
          slug
        }
        broker_types {
          contentful_id
        }
        platforms {
          contentful_id
          name
          name_ru
        }
        instruments {
          contentful_id
          name
          name_ru
        }
        regulators {
          contentful_id
        }
        funding_methods {
          contentful_id
          name
          name_ru
          logo {
            fluid(maxHeight: 20) {
              aspectRatio
              src
              sizes
            }
          }
        }
        countries {
          contentful_id
          name
          name_ru
          flag {
            fluid(maxHeight: 20) {
              aspectRatio
              src
              sizes
            }
          }
        }
        logo {
          fluid {
            srcWebp
            base64
            aspectRatio
            src
            srcSet
            sizes
            srcSetWebp
          }
        }
      }
    }
  }
}
    `;

    const { allContentfulBroker: { edges: brokers } = {} } = useStaticQuery(GET_BROKERS);
    const list = brokers.map(elem => elem.node);

    const options = {
        includeScore: true,
        keys: ['name']
      }
      
    const fuse = new Fuse(list, options)

    const onChange = (e) => {
        
      /* if(e.target.value === '') { navigate('/') } */
      setValue(e.target.value);
      const results = fuse.search(tr(e.target.value))

      searchStore().update(results.slice(0, 14)/* .map(elem => elem.item.slug) */ );
    };

    React.useEffect(() => {
        if(typeof window !== 'undefined' && window.location.pathname === '/search') {
            const input = document.querySelector("input");
            input.focus();
        }
    }, [])



    return (
        <EuiFieldSearch
            placeholder="Поиск по брокерам"
            value={value}
            fullWidth
            /* compressed */
            onFocus={() => { if(typeof window !== 'undefined' && !window.location.pathname.includes('/search')) { navigate('/search') } }} 
            onChange={(e) => onChange(e)}
            isClearable={isClearable}
            aria-label="Use aria labels when no actual label is in use"
        />
    );
}

export default Search;